import React from 'react';

function About() {
  return (
    <section className="about-section">
      <h2>About</h2>
      <p>Details about your company or personal info</p>
    </section>
  );
}

export default About;
