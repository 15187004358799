import React from 'react';

function Portfolio() {
  return (
    <section className="portfolio">
      <h2>Portfolio</h2>
      {/* Add your portfolio content here */}
    </section>
  );
}

export default Portfolio;
